<template>
    <div>
        <vx-card>
            <div class="vx-row">
                <div class="vx-col md:w-full flex items-center mb-5">
                    <TitleScreen></TitleScreen>
                </div>
            </div>
            <vs-tabs>
                <vs-tab :label="$t('menuOrganization.personalInformation')">
                    <div>
                        <div class="vx-row">
                            <div class="vx-col md:w-3/4 w-full">
                                <!-- Col Header -->
                                <div class="flex items-end mt-6 mb-3">
                                    <feather-icon icon="SidebarIcon" class="mr-2" svgClasses="w-6 h-6" />
                                    <span class="leading-none font-medium fsz font-bold">Đơn vị - Bộ phận</span>
                                </div>

                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{ $t('menuOrganization.branch') }} </label>
                                        <span class="text-danger">*</span>
                                        <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                                data-vv-validate-on="blur" :disabled="isUpdate || userInfo.userRole != 'organization'" v-validate="'required'"
                                                name="branch">
                                            <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                        </v-select>
                                        <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                                    </div>

                                    <div class="vx-col md:w-1/3 w-full mt-2" v-if="!isCreateDepartment">
                                        <label class="vs-input--label">{{ $t('menuOrganization.department') }} </label>
                                        <v-select class="" v-model="departmentModel" :clearable="false"
                                                :options="departmentOptions"
                                                data-vv-validate-on="blur">
                                            <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                        </v-select>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full mt-2" v-if="isCreateDepartment">
                                        <label class="vs-input--label">{{ $t('menuOrganization.createDepartment') }}</label>
                                        <vs-input class="w-full"
                                                v-model="departmentName" name="departmentName"/>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full mt-6">
                                        <vs-button class="mt-2" type="border" v-if="!isCreateDepartment"
                                                @click="createDepartment">{{ $t('button.addNew') }}
                                        </vs-button>
                                        <vs-button class="mt-2" type="border" v-if="isCreateDepartment" @click="saveDepartment">
                                            {{ $t('button.save') }}
                                        </vs-button>
                                        <vs-button class="ml-4 mt-2" type="border" v-if="isCreateDepartment"
                                                @click="cancelCreateDeparment">{{ $t('button.back') }}
                                        </vs-button>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{ $t('menuOrganization.position') }} </label>
                                        <vs-input class="w-full" v-model="employeeInfo.position"
                                                v-validate="" name="position"/>
                                        <span class="text-danger text-sm">{{ errors.first('position') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{ $t('menuOrganization.employeeType') }} </label>
                                        <span class="text-danger">*</span>
                                        <v-select class="" v-model="employeeTypeModel" :clearable="false"
                                                :options="employeeTypeOptions"
                                                data-vv-validate-on="blur" v-validate="'required'" name="type">
                                            <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                        </v-select>
                                        <span class="text-danger text-sm">{{ errors.first('type') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{ $t('menuOrganization.salary') }} <span class="text-danger">*</span></label>
                                        <vs-input class="w-full" v-model="employeeInfo.salary"
                                                v-validate="'required|numeric'" name="salary"/>
                                        <span class="text-danger text-sm">{{ errors.first('salary') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col md:w-1/4 w-full" style="border-left: 1px solid #DCDCDC;">
                                <!-- Col Header -->
                                <div class="flex mt-6 mb-3 items-end ml-12">
                                    <feather-icon icon="MaximizeIcon" class="mr-2" svgClasses="w-6 h-6" />
                                    <span class="leading-none font-medium fsz font-bold">{{ $t('menuCustomer.faceData') }}</span>
                                </div>
                                <div class="vx-row">
                                    <vue-upload-multiple-image
                                        class="m-auto"
                                        @upload-success="uploadImageSuccess"
                                        @before-remove="beforeRemove"
                                        @edit-image="editImage"
                                        @data-change="dataChange"
                                        :data-images="images"
                                        :dragText="$t('menuOrganization.dragText')"
                                    ></vue-upload-multiple-image>
                                </div>
                            </div>
                        </div>

                        <div class="vx-row mt-4">
                            <div class="vx-col md:w-3/4 w-full">
                                <!-- Col Header -->
                                <div class="flex items-end mt-8 mb-3">
                                    <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-6 h-6" />
                                    <span class="leading-none font-medium fsz font-bold">Lý lịch - Liên hệ</span>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{$t('menuOrganization.employeeCode')}} <span class="text-danger">*</span></label>
                                        <vs-input class="w-full" :disabled="isUpdate"
                                                v-model="employeeInfo.employeeCode"
                                                v-validate="'required|max:20'" name="employeeCode"/>
                                        <span class="text-danger text-sm">{{ errors.first('employeeCode') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/6 w-full flex mt-8" v-show="isUpdate">
                                        <vs-checkbox v-model="employeeInfo.enabled" class="mb-1"></vs-checkbox>
                                        <span class="mt-2">{{ $t('menuOrganization.working') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/6 w-full mt-8 flex" v-if="isShowManagerDepartment">
                                        <vs-checkbox v-model="employeeInfo.departmentType"
                                                    class="mb-1"></vs-checkbox>
                                        <span class="mt-2">{{ $t('menuOrganization.Manager') }}</span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{$t('menuOrganization.firstName')}} <span class="text-danger">*</span></label>
                                        <vs-input class="w-full" v-model="firstNameModel"
                                                v-validate="'required|max:20'" name="firstName"/>
                                        <span class="text-danger text-sm">{{ errors.first('firstName') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{$t('menuOrganization.lastName')}} <span class="text-danger">*</span></label>
                                        <vs-input class="w-full" v-model="lastNameModel"
                                                v-validate="'required|max:20'" name="lastName"/>
                                        <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>
                                    </div>
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <vx-input-group class="">
                                            <label class="vs-input--label">{{$t('menuOrganization.userName')}} <span class="text-danger">*</span></label>
                                            <vs-input class="w-full" :disabled="isUpdate"
                                                v-model="userNameModel"
                                                    @input="checkLengthUserName"
                                                v-validate="'required|max:50'" name="userName"/>
                                            <template v-if="!isUpdate" slot="append">
                                                <div class="append-text btn-addon mt-6">
                                                    <vs-button :disabled="isMaxLength" @click="checkUserName(true)" color="primary"
                                                            style="height: 38px">{{ $t('button.check') }}
                                                    </vs-button>
                                                </div>
                                            </template>
                                        </vx-input-group>
                                        <span class="text-danger text-sm">{{ errors.first('userName') }}</span>
                                    </div>
                                </div>
                                <div class="vx-row">
                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{ $t('menuOrganization.birthday') }} <span class="text-danger">*</span></label>
                                        <datepicker :highlighted="highlighted" :disabledDates="disabledFn" :language="vi" :format="'dd/MM/yyyy'" v-model="employeeInfo.birthday"
                                                    data-vv-validate-on="input" v-validate="'required'"
                                                    name="birthday"></datepicker>
                                        <span class="text-danger text-sm">{{ errors.first('birthday') }}</span>
                                    </div>

                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">Email <span class="text-danger">*</span></label>
                                        <vs-input class="w-full" v-model="employeeInfo.email"
                                                v-validate="'required|email'" name="email"/>
                                        <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                                    </div>

                                    <div class="vx-col md:w-1/3 w-full mt-2">
                                        <label class="vs-input--label">{{ $t('menuOrganization.phoneNumber') }} </label>
                                        <vs-input class="w-full"  v-model="employeeInfo.phoneNumber"
                                                v-validate="'numeric|min:10|max:11'" name="phoneNumber"/>
                                        <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
                                    </div>
                                    <div class="vx-col w-full mt-2">
                                        <label class="vs-input--label">{{ $t('menuOrganization.gender') }} </label>
                                        <span class="text-danger">*</span>
                                        <div class="mt-2 ml-1">
                                            <vs-radio v-model="employeeInfo.gender" vs-value="1" class="mr-4">Nam</vs-radio>
                                            <vs-radio v-model="employeeInfo.gender" vs-value="2" class="mr-4">Nữ</vs-radio>
                                            <vs-radio v-model="employeeInfo.gender" vs-value="0">Chưa xác định</vs-radio>
                                        </div>
                                        <span class="text-danger text-sm">{{ errors.first('gender') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="vx-col md:w-1/4 w-full">
                                <!-- Col Header -->
                                <div class="flex items-end mt-8 mb-3 ml-12">
                                    <feather-icon icon="RadioIcon" class="mr-2" svgClasses="w-6 h-6" />
                                    <span class="leading-none font-medium fsz font-bold">{{ $t('navMenu.externalDevice') }}</span>
                                </div>

                                <div class="vx-row">
                                    <div class="vx-col w-full">
                                        <!-- <feather-icon  class="cursor-pointer ml-2" icon="PlusIcon" icon-pack="feather" style="vertical-align:middle; color:#ccc" @click="openPopupCard"/> -->
                                        <vs-button class="mt-4 m-auto" icon-pack="feather" icon="icon-plus" @click="openPopupCard" v-if="!employeeInfo.cardId || !isUpdate">
                                            {{ $t('button.addCard') }}
                                        </vs-button>
                                        <vs-popup title="Chọn thẻ" :active.sync="activePopup">
                                            <div class="vx-row">
                                                <div class="vx-col w-full text-center" v-if="!listCards && listCards.length == 0">
                                                    <span>{{ $t('cardCustomer.noData') }}</span>
                                                </div>
                                                <div class="vx-col md:w-1/3 w-full" v-for="(card, indexCard) in listCards" :key="indexCard" v-else>
                                                    <div class="w-full" @click="chooseCard(card)">
                                                        <card-customer class="mt-4" :data="card" :isEdit="false"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </vs-popup>
                                    </div>
                                    <div class="vx-col w-full m-auto" v-show="employeeInfo.cardId" style="min-height:300px;max-width:300px">
                                        <card-customer style="min-height:300px" class="mt-4 " :data="employeeInfo" :isEdit="isUpdate && employeeInfo.cardId && employeeInfo.cardStaff" :disableCard="true" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Col Header -->
                        <div class="flex items-end mt-10 mb-3">
                            <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-6 h-6" />
                            <span class="leading-none font-medium fsz font-bold">Địa chỉ</span>
                        </div>

                        <div class="vx-row">
                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <label class="vs-input--label">{{ $t('menuOrganization.city') }} </label>
                                <span class="text-danger">*</span>
                                <v-select class="" v-model="province" :clearable="false" :options="cityOptions"
                                        data-vv-validate-on="blur" v-validate="'required'" name="city">
                                        <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                </v-select>
                                <span class="text-danger text-sm">{{ errors.first('city') }}</span>
                            </div>
                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <label class="vs-input--label">{{ $t('menuOrganization.district') }} </label>
                                <span class="text-danger">*</span>
                                <v-select class="" v-model="district" :clearable="false" :options="districtOptions"
                                        data-vv-validate-on="blur" v-validate="'required'" name="district">
                                        <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                </v-select>
                                <span class="text-danger text-sm">{{ errors.first('district') }}</span>
                            </div>

                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <label class="vs-input--label">{{ $t('menuOrganization.commune') }} </label>
                                <span class="text-danger">*</span>
                                <v-select class="" v-model="subDistrict" :clearable="false"
                                        :options="subDistrictOptions"
                                        data-vv-validate-on="blur" v-validate="'required'" name="subDistrict">
                                    <span slot="no-options">{{ $t('vSelect.noRowsToShow') }}</span>
                                </v-select>
                                <span class="text-danger text-sm">{{ errors.first('subDistrict') }}</span>
                            </div>
                        </div>

                        <div class="vx-row">
                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <label class="vs-input--label">{{ $t('menuOrganization.houseNumber') }}
                                <span class="text-danger">*</span></label>
                                <vs-input class="w-full" v-model="employeeInfo.houseNumber"
                                        v-validate="'required|max:50'" name="houseNumber"/>
                                <span class="text-danger text-sm">{{ errors.first('houseNumber') }}</span>
                            </div>
                            <div class="vx-col md:w-2/3 w-full mt-2">
                                <label class="vs-input--label">{{ $t('menuOrganization.address') }} </label>
                                <vs-input class="w-full" v-model="fullAddressModel"
                                        v-validate="''" name="address" readonly="true"/>
                            </div>
                        </div>

                        <div class="vx-row md:mb-1 mb-4 mt-4" v-for="(item, indexWork) in workShiftSetting" :key="indexWork">
                            <div class="vx-col w-full mt-4" v-if="indexWork === 0">
                                <span class="ml-2 mt-6 font-bold" style="font-size: 18px">{{ $t('menuOrganization.workingTime') }}</span>
                            </div>
                            <div class="vx-col lg:w-1/2 w-full flex mt-4">
                                <vs-checkbox v-model="item.select" class="mb-1"></vs-checkbox>
                                <span>{{ item.displayWorkShift }}</span>
                            </div>
                            <!-- <div class="vx-col lg:w-1/2 w-full">
                              <div>{{item.displayWorkShift}}</div>
                            </div> -->
                            <div class="vx-col lg:w-1/4 w-full flex">
                                <vs-checkbox v-model="item.autoGenerateWorkShift" class="mb-1"></vs-checkbox>
                                <span class="mt-2">{{ $t('menuOrganization.autoGenerate') }}</span>
                            </div>
                        </div>

                        <!-- Save & Reset Button -->
                        <div class="vx-row">
                            <div class="vx-col w-full">
                                <div class="flex flex-wrap items-center justify-end">
                                    <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                            `${isUpdate ?
                                                $t('button.update') : $t('button.create')}`
                                        }}
                                    </vs-button>
                                    <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.go(-1)">
                                        {{ $t('button.back') }}
                                    </vs-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </vs-tab>
                <vs-tab :label="$t('menuOrganization.authority')" v-if="isShowPermission">
                    <div class="mt-3">
                        <v-jstree :data="permissions" show-checkbox multiple allow-batch whole-row
                                  @item-click="itemClick"></v-jstree>
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full">
                            <div class="flex flex-wrap items-center justify-end">
                                <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                        `${isUpdate ?
                                            $t('button.update') : $t('button.create')}`
                                    }}
                                </vs-button>
                                <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.go(-1)">
                                    {{ $t('button.back') }}
                                </vs-button>
                            </div>
                        </div>
                    </div>
                </vs-tab>

                <vs-tab :label="$t('menuOrganization.rePass')" v-if="isShowPermission && isUpdate">
                    <div>
                        <div class="vx-row">
                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <label class="vs-input--label">{{ $t('menuOrganization.branch') }} </label>
                                <span class="text-danger">*</span>
                                <v-select class="" v-model="branchModel" :clearable="false" :options="branchOptions"
                                          data-vv-validate-on="blur" :disabled="isUpdate" v-validate="'required'"
                                          name="branch"/>
                                <span class="text-danger text-sm">{{ errors.first('branch') }}</span>
                            </div>
                            <div class="vx-col md:w-1/3 w-full mt-2">
                                <label class="vs-input--label">{{$t('menuOrganization.employeeCode')}} </label>
                                <span class="text-danger">*</span>
                                <vs-input class="w-full" :disabled="isUpdate"
                                          v-model="employeeInfo.employeeCode"
                                          v-validate="'required'" name="employeeCode"/>
                                <span class="text-danger text-sm">{{ errors.first('employeeCode') }}</span>
                            </div>
                        </div>

                        <div class="vx-row">
                            <div class="vx-col md:w-2/3 w-full mt-2">
                                <label class="vs-input--label">{{$t('menuOrganization.newPass')}} </label>
                                <span class="text-danger">*</span>
                                <vs-input id="iban" class="w-full"
                                          v-model="newPassword"
                                          v-validate="'required|min:6'" name="newPassword" @keypress="checkKeypressDrop($event)"/>
                                <span class="text-danger text-sm">{{ errors.first('newPassword') }}</span>
                            </div>
                            <div class="vx-col w-full mt-2">
                                <div class="flex flex-wrap items-center justify-end">
                                    <vs-button class="ml-auto mt-2" @click="changePassword" :disabled="!validateForm">{{
                                            `${isUpdate ?
                                                $t('button.update') : $t('button.create')}`
                                        }}
                                    </vs-button>
                                    <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.go(-1)">
                                        {{ $t('button.back') }}
                                    </vs-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>

    </div>
</template>

<script>
import CardCustomer from '../card-customer/card/CardCustomer.vue'
import vSelect from 'vue-select'
import province from '@/assets/utils/province.js';
import Datepicker from 'vuejs-datepicker';
import utils from "../../../components/utils";
import VueUploadMultipleImage from '../../../components/VueUploadMultipleImage'
import VJstree from 'vue-jstree';
import {en, vi} from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        vSelect,
        Datepicker,
        VueUploadMultipleImage,
        VJstree,
        TitleScreen,
        CardCustomer
    },
    data() {
        return {
            activePopup: false,
            cardCustomer: {
                cardId: null
            },
            listCards: [],
            checkUsernameExisted: true,
            isMaxLength: false,
            highlighted : {
                dates: [
                    new Date()
                ]
            },
            userInfo: {},
            en: en,
            vi: vi,
            departmentName: null,
            isCreateDepartment: false,
            isShowManagerDepartment: false,
            departmentOptions: [],
            isShowPermission: false,
            permissions: [],
            privileges: [],
            images: [],
            imagesId: [],
            isUpdateImages: false,
            user_data: null,
            activeTab: 0,
            isUpdate: false,
            isUpdateFace: false,
            branchOptions: [],
            employeeInfo: {
                cardNumber: null,
                cardStaffType: null
            },
            workShiftSetting: [],
            timeSpans: [],
            newPassword: null,
            genderOptions: [
                {value: 0, label: this.$t('genderOption.unknown')},
                {value: 1, label: this.$t('genderOption.male')},
                {value: 2, label: this.$t('genderOption.female')}
            ],
            employeeTypeOptions: [
                {value: 0, label: this.$t('employeeTypeOptions.unknown')},
                {value: 1, label: this.$t('employeeTypeOptions.trainee')},
                {value: 2, label: this.$t('employeeTypeOptions.officialStaff')}
            ],
            cityOptions: province.getProvince(),
            districtOptions: [],
            subDistrictOptions: [],
            file: [],
            fileName: this.$t('menuOrganization.addFace'),
            uploadPercent: 0,
            disabledFn: {
                customPredictor(date) {
                    if (moment(date).isAfter(moment(), 'days')) {
                        return true;
                    }
                }
            },
        }
    },
    watch: {
        '$store.state.isDeleteCardCustomer'(val) {
            if (val) {
                this.employeeInfo.cardId = null;
                this.$store.commit('DELETE_CARD', false);
                this.getDataCard()
            }
        }
    },
    async created() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userInfo = userInfo;
        await this.getDataCard();

        this.$vs.loading();
        let timeDuration = 30, timeSpans = [];
        for (let i = 0; i < 24 * 60; i = i + timeDuration) {
            timeSpans.push({value: i, label: utils.minuteToHour(i)});
        }
        this.timeSpans = timeSpans;
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        this.branchOptions = branchList.map(x => {
            return {label: x.organizationBranchName, value: x.id}
        });

        this.employeeInfo = {
            organizationBranchName: null,
            city: null,
            district: null,
            fullAddress: null,
            houseNumber: null,
            organizationBranchType: 0,
            organizationBranchId: this.$route.query.branch_id,
            phoneNumber: null,
            street: null,
            gender: 0,
            type: 0,
            salary: 0,
            departmentId: null,
            departmentType: 0,
            enabled: true,
            // username: null,
            // firstName: null,
            // lastName: null,
            cardStaffId: null,
            cardStatus: null,
            cardNumber: null,
            cardStaffType: null
        };
        let employeeId = this.$route.query.id;
        this.getDepartment();
        this.loadWorkShiftSetting();
        if (employeeId) {
            await this.getUserInfo(employeeId);
            for (const x of this.employeeInfo.imageStore) {
                let url = "https://cdnimage.intface.vn/" + x.fileId;
                let data = await this.toDataURL(url);
                let dataImage = data.xhr.response;
                let metadata = {
                    type: 'image/jpeg'
                };
                let file = new File([dataImage], x.fileName, metadata);
                this.images.push({
                    name: x.fileName,
                    path: data.imgBase64,
                    highlight: 1,
                    default: 1,
                    file: file
                });
            }
        } else {
            await this.getAllPermission([]);
            this.isShowPermission = true;
        }
        this.$vs.loading.close();
    },
    computed: {
        cardCustomerModel: {
            get(){
                let object = this.listCards.find(el => el.value == this.cardCustomer.cardId);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object){
                this.cardCustomer.cardId = object ? object.value : null;
            }
        },
        branchModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.employeeInfo.organizationBranchId)),
                    value: this.employeeInfo.organizationBranchId
                }
            },
            set(obj) {
                this.employeeInfo.organizationBranchId = obj.value;
                this.loadWorkShiftSetting()
                this.getDepartment();
            }
        },
        departmentModel: {
            get() {
                return {
                    label: this.getLabelString(this.departmentOptions.find(x => x.value === this.employeeInfo.departmentId)),
                    value: this.employeeInfo.departmentId
                }
            },
            set(obj) {
                this.employeeInfo.departmentId = obj.value;
                this.employeeInfo.departmentType = false;
                if (this.employeeInfo.departmentId) {
                    this.isShowManagerDepartment = true;
                } else {
                    this.isShowManagerDepartment = false;
                }
            }
        },
        validateForm() {
            return !this.errors.any()
        },
        genderModel: {
            get() {
                return {
                    label: this.getLabelString(this.genderOptions.find(x => x.value === this.employeeInfo.gender)),
                    value: this.employeeInfo.gender
                }
            },
            set(obj) {
                this.employeeInfo.gender = obj.value;
            }
        },
        employeeTypeModel: {
            get() {
                return {
                    label: this.getLabelString(this.employeeTypeOptions.find(x => x.value === this.employeeInfo.type)),
                    value: this.employeeInfo.type
                }
            },
            set(obj) {
                this.employeeInfo.type = obj.value;
            }
        },
        province: {
            get() {
                return {
                    label: this.getLabelString(this.cityOptions.find(x => x.value === this.employeeInfo.city)),
                    value: this.employeeInfo.city
                }
            },
            set(obj) {
                if (obj.value) {
                    this.employeeInfo.city = obj.value;
                    this.districtOptions = province.getDistrict(obj.value);
                    this.employeeInfo.district = this.districtOptions[0].value;
                    this.subDistrictOptions = province.getSubDistrict(this.employeeInfo.city, this.employeeInfo.district);
                    this.employeeInfo.street = this.subDistrictOptions[0].value;
                } else {
                    this.employeeInfo.city = null;
                    this.districtOptions = [{value: null, label: this.$t('cardCustomer.allCard')}];
                    this.employeeInfo.district = null;
                    this.subDistrictOptions = [{value: null, label: this.$t('cardCustomer.allCard')}];
                    this.employeeInfo.street = null;
                }
            }
        },
        district: {
            get() {
                return {
                    label: this.getLabelString(this.districtOptions.find(x => x.value === this.employeeInfo.district)),
                    value: this.employeeInfo.district
                }
            },
            set(obj) {
                this.employeeInfo.district = obj.value;
                this.subDistrictOptions = province.getSubDistrict(this.employeeInfo.city, obj.value);
                this.employeeInfo.street = this.subDistrictOptions[0].value;
            }
        },
        subDistrict: {
            get() {
                return {
                    label: this.getLabelString(this.subDistrictOptions.find(x => x.value === this.employeeInfo.street)),
                    value: this.employeeInfo.street
                }
            },
            set(obj) {
                this.employeeInfo.street = obj.value
            }
        },
        fullAddressModel: {
            get() {
                if (this.province.value) {
                    let fullAddress = `${this.employeeInfo.houseNumber ? this.employeeInfo.houseNumber + ', ' : ''}${this.subDistrict.label ? this.subDistrict.label + ', ' : ''}${this.district.label}, ${this.province.label}`;
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.employeeInfo.fullAddress = fullAddress;
                    return fullAddress;
                } else {
                    return null;
                }
            },
        },
        userNameModel: {
            get() {
                return this.employeeInfo.username;
            },
            set(val) {
                this.employeeInfo.username = val;
            }
        },
        firstNameModel: {
            get() {
                return this.employeeInfo.firstName;
            },
            set(value) {
                this.employeeInfo.firstName = value;
                this.changValueUserName();
            }
        },
        lastNameModel: {
            get() {
                return this.employeeInfo.lastName;
            },
            set(value) {
                this.employeeInfo.lastName = value;
                this.changValueUserName();
            }
        },
    },
    methods: {
        customerType(type){
            if (type === 0) this.employeeInfo.customerType = this.$t('cardCustomer.customer')
            if (type === 1) this.employeeInfo.customerType = this.$t('cardCustomer.employee')
            if (type === 2) this.employeeInfo.customerType = this.$t('cardCustomer.unknown')
        },
        chooseCard: function(card){
            this.employeeInfo.cardNumber = card.cardStaffId;
            this.employeeInfo.cardStaffType = card.cardStaffType;
            this.employeeInfo.cardId = card.value;
            this.customerType(card.cardStaffType);
            this.activePopup = false;
        },
        openPopupCard: function(){
            this.activePopup = true;
        },
        getDataCard: function(){
            const payload = {
                cardStatus: 0,
                cardStaffType: 2,
                sort: 'id',
                organizationId: this.userInfo.organization.id
            }
            return new Promise((resolve, reject) => {
                this.$vs.loading();
            this.$crm.post('/card-staff/find-by-condition', payload).then((res) => {
                this.listCards = res.data.content.map(el => {
                    return {
                        label: el.cardNumber,
                        value: el.id,
                        cardStaffType: el.cardStaffType,
                        cardStaffId: el.cardNumber
                    }
                });
                this.$vs.loading.close();
                resolve();
            }).catch((err) => {
                reject();
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
            })
        },
        checkKeypressDrop: function(event){
            event.target.value = event.target.value.trim();
            return event;
        },
        changValueUserName() {
            if(this.isUpdate || !this.employeeInfo.lastName || !this.employeeInfo.firstName) {
                return
            }
            let username = `${this.employeeInfo.lastName ? this.employeeInfo.lastName : ''}`
            const firstNames = this.employeeInfo.firstName.split(' ');
            firstNames.forEach(item => {
                username += item.substr(0, 1)
            });
            this.employeeInfo.username = username
                .split(' ')
                .join('')
                .replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, "a")
                .replace(/[èéẹẻẽêềếệểễ]/g, "e")
                .replace(/[ìíịỉĩ]/g, "i")
                .replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, "o")
                .replace(/[ùúụủũưừứựửữ]/g, "u")
                .replace(/[ỳýỵỷỹ]/g, "y")
                .replace(/đ/g, "d")
                .replace(/[ÀÁẠẢÃÂẦẤẬẨẪĂẰẮẶẲẴ]/g, "A")
                .replace(/[ÈÉẸẺẼÊỀẾỆỂỄ]/g, "E")
                .replace(/[ÌÍỊỈĨ]/g, "I")
                .replace(/[ÒÓỌỎÕÔỒỐỘỔỖƠỜỚỢỞỠ]/g, "O")
                .replace(/[ÙÚỤỦŨƯỪỨỰỬỮ]/g, "U")
                .replace(/[ỲÝỴỶỸ]/g, "Y")
                .replace(/Đ/g, "D")
                .replace(/[\u0300\u0301\u0303\u0309\u0323]/g, "")
                .replace(/[\u02C6\u0306\u031B]/g, "")
                // eslint-disable-next-line no-useless-escape
                .replace(/[!@%^*()+=<>?\/,.:;'"&#\[\]~$_`\-{}|\\]/g, " ")
                .trim()
                .toLowerCase();
        },
        checkUserName: function (check)  {
            return new Promise((resolve, reject) => {
                this.$oauth.get(`/user/check-username?username=${this.employeeInfo.username}`).then((response) => {
                    this.$vs.loading.close();
                    if (!response.data) {
                        resolve();
                        if (check) {
                            this.$vs.notify({
                                color: 'success',
                                text: this.$t('menuOrganization.validUsername'),
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                            });
                        }
                        this.checkUsernameExisted = response.data;
                    } else {
                        resolve();
                        this.$vs.notify({
                            color: 'danger',
                            text: this.$t('menuOrganization.userExists'),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                        });
                        this.checkUsernameExisted = response.data;
                    }
                }).catch(() => {
                    reject();
                })
            })
        },
        async changePassword(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;

            const result = await this.$validator.validateAll();
            if (!result) return;

            let url = "/user/org-reset-password";
            this.$oauth.post(url, null,{
                params: {
                    employeeCode: this.employeeInfo.employeeCode,
                    password: this.newPassword
                }
            }).then(() => {
                this.$vs.loading.close();
                this.$vs.notify({
                    color: 'success',
                    text: `Thay đổi mật khẩu thành công`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$router.push(`/user/manage-employee${this.password ?
                    `?branch_id=${this.password}` : ''}`).catch(() => {
                })
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        toDataURL(url) {
            return new Promise(resolve => {
                let xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    let reader = new FileReader();
                    reader.onloadend = function () {
                        resolve({imgBase64: reader.result, xhr: xhr});
                    }
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url, true);
                xhr.responseType = 'blob';
                xhr.send();
            })
        },
        checkLengthUserName(){
            if(this.userNameModel.length > 50){
                this.isMaxLength = true
                this.userNameModel = this.userNameModel.trim();
            } else {
                this.isMaxLength = false
                this.userNameModel = this.userNameModel.trim();
            }
        },
        getUserInfo(employeeId) {
            this.$vs.loading();
            return new Promise(resolve => {
                this.$vs.loading();
                this.$crm.post('/employee/find-by-id/' + employeeId).then(async (response) => {

                    if (response.data.departmentDTO) {
                        response.data.departmentId = response.data.departmentDTO.id;
                        this.isShowManagerDepartment = true;
                    } else {
                        response.data.departmentId = null;
                    }
                    delete response.data.departmentDTO;
                    this.employeeInfo = response.data;
                    this.employeeInfo.cardId = response.data.cardId;
                    if (this.employeeInfo.cardId) {
                        this.customerType(1)
                        this.employeeInfo.cardStatus = 1;
                    }
                    if (response.data.cardStaff) this.employeeInfo.cardNumber = response.data.cardStaff.cardNumber;
                    this.employeeInfo.cardStaffType = 1;
                    let dataImage = response.data.imageStore;
                    if (dataImage && dataImage.length > 0) this.employeeInfo.urlImage = dataImage[0].url;
                    if (this.employeeInfo && !this.employeeInfo.username) this.changValueUserName();
                    this.districtOptions = province.getDistrict(this.employeeInfo.city);
                    this.subDistrictOptions = province.getSubDistrict(this.employeeInfo.city, this.employeeInfo.district);
                    if (Array.isArray(this.employeeInfo.workShiftSetting)) {
                        this.workShiftSetting.forEach(item => {
                            let setting = this.employeeInfo.workShiftSetting.find(setting => {
                                return item.id == setting.workShiftSettingId;
                            })
                            if (setting) {
                                item.select = true;
                                item.autoGenerateWorkShift = setting.autoGenerateWorkShift;
                            } else {
                                item.select = false
                                item.autoGenerateWorkShift = false;
                            }
                        })
                    }
                    await this.getAllPermission(this.employeeInfo.privileges);
                    this.isShowPermission = true;
                    this.isUpdate = true;
                    resolve(true);
                    this.$vs.loading.close();
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },
        createDepartment() {
            this.employeeInfo.departmentId = null;
            this.isShowManagerDepartment = false;
            this.departmentName = null;
            this.isCreateDepartment = true;
        },
        saveDepartment() {
            if (!this.departmentName) {
                return this.$vs.notify({
                    text: this.$t('menuOrganization.cannotName'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            let dataRequest = {
                departmentName: this.departmentName,
                organizationBranchId: this.employeeInfo.organizationBranchId
            }
            this.$vs.loading();
            this.$crm.post('/department/create', dataRequest).then(() => {
                this.isCreateDepartment = false;
                this.getDepartment();
                setTimeout(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.$t('warningMenuOrganization.newDepartments')} ${this.departmentName} ${this.$t('warningMenuOrganization.success')}`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                }, 200)
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        cancelCreateDeparment() {
            this.isCreateDepartment = false;
        },
        getDepartment() {
            this.$vs.loading();
            let searchInfo = {
                organizationBranchId: this.employeeInfo.organizationBranchId
            }
            this.$crm.post('/department/find-by-condition', searchInfo).then((response) => {
                this.departmentOptions = response.data.map(x => {
                    return {
                        value: x.id,
                        label: x.departmentName
                    }
                });
                this.departmentOptions.unshift({value: null, label: this.$t('employeeTypeOptions.unknown')});
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getAllPermission(arrPermissions) {
            return new Promise(resolve => {
                // this.$vs.loading();
                this.$oauth.get('/permission').then((response) => {
                    let permissions = response.data.reduce((groups, item) => {
                        const group = (groups[this.$i18n.locale == 'vn' ? item.aliasGroupName : item.aliasGroupNameEng] || []);
                        group.push(item);
                        groups[this.$i18n.locale == 'vn' ? item.aliasGroupName : item.aliasGroupNameEng] = group;
                        return groups;
                    }, {});
                    for (const property in permissions) {
                        let permission = {
                            text: property,
                            children: [],
                            icon: "fa fa-warning icon-state-danger",
                            opened: true,
                        }
                        permissions[property].forEach(value => {
                            value.text = this.$i18n.locale == 'vn' ? value.aliasName : value.aliasNameEng;
                            value.icon = "fa fa-warning icon-state-danger";
                            value.opened = true;
                            arrPermissions.filter(x => {
                                if (x == value.name) {
                                    value.selected = true;
                                    this.privileges.push(value.id);
                                }
                            })
                            permission.children.push(value);
                        })
                        let selectedFullChild = 0;
                        permission.children.forEach(x => {
                            if (x.selected == true) selectedFullChild++;
                        })
                        if (permission.children.length == selectedFullChild) permission.selected = true;
                        this.permissions.push(permission);
                    }
                    resolve(true);
                    this.$vs.loading.close();
                }).catch(() => {
                    this.$vs.loading.close();
                })
            })
        },
        itemClick(node) {
            if (node.data.children && node.data.children.length > 0) {
                if (node.data.selected) {
                    node.data.children.forEach(x => {
                        let privilege = this.privileges.find(privilege => privilege == x.id);
                        if (!privilege) this.privileges.push(x.id);
                    })
                } else {
                    node.data.children.forEach(x => {
                        this.privileges = this.privileges.filter(privilege => privilege != x.id);
                    })
                }
            } else {
                if (node.data.selected) {
                    this.privileges.push(node.data.id);
                } else {
                    this.privileges = this.privileges.filter(x => x != node.data.id);
                }
            }
        },
        uploadImageSuccess(formData, index, fileList, file) {
            this.images = fileList;
            this.employeeInfo.urlImage = fileList.length > 0 ? fileList[0].path : null;
            this.setIsUpdateImages();
        },
        beforeRemove(index, done, fileList) {
            this.images = fileList;
            this.setIsUpdateImages();
            done();
        },
        editImage(formData, index, fileList) {
            this.images = fileList;
            this.setIsUpdateImages();
        },
        dataChange(data) {
            this.images = fileList;
            this.employeeInfo.urlImage = data;
            this.setIsUpdateImages();
        },
        setIsUpdateImages() {
            if (this.isUpdate) {
                this.isUpdateImages = true;
            }
        },
        getLabelString(object) {
            return (object && object.label) || this.$t('menuOrganization.null');
        },
        manufactureString(type) {
            let typeString = this.manufactureOptions.find(x => x.value === type);
            return (typeString && typeString.label) || this.$t('menuOrganization.null');
        },
        loadWorkShiftSetting() {
            this.$vs.loading();
            this.$crm.post('/work-shift/organization/find-by-organization-branch/' + this.employeeInfo.organizationBranchId).then((response) => {
                this.workShiftSetting = response.data.workShiftSetting.map(item => {
                    if (this.isUpdate) {
                        let setting = this.employeeInfo.workShiftSetting.find(setting => {
                            return item.id == setting.workShiftSettingId;
                        })
                        if (setting) {
                            item.select = true;
                            item.autoGenerateWorkShift = setting.autoGenerateWorkShift;
                        } else {
                            item.select = false
                            item.autoGenerateWorkShift = false;
                        }
                    } else {
                        item.select = true;
                        item.autoGenerateWorkShift = true;
                    }
                    item.displayWorkShift = "Nhóm " + item.groupName + " - Ca " + item.name + ": " + utils.minuteToHour(item.startHour) + "-" + utils.minuteToHour(item.endHour);
                    return item;
                });
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: this.$t('message.error'),
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        async saveChanges(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;

            const result = await this.$validator.validateAll();
            if (!result) return;

            if (new Date(this.employeeInfo.birthday).getTime() > new Date().getTime()) {
                return this.$vs.notify({
                    title: this.$t('message.error'),
                    text: this.$t('menuOrganization.theFuture'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            if (!this.employeeInfo.city) {
                return this.$vs.notify({
                    title: this.$t('message.error'),
                    text: this.$t('menuOrganization.geolocation'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            }
            // if (this.images.length === 0)
            //     return this.$vs.notify({
            //         text: 'Avata chưa được chọn. ',
            //         iconPack: 'feather',
            //         icon: 'icon-alert-circle',
            //         color: 'warning'
            //     });
            if(!this.isUpdate){
                await this.checkUserName(false);
                if (this.checkUsernameExisted) return;
            }
            this.employeeInfo.workShiftSetting = this.workShiftSetting.reduce((arr, item) => {
                if (item.select) {
                    arr.push({workShiftSettingId: item.id, autoGenerateWorkShift: item.autoGenerateWorkShift ? 1 : 0});
                }
                return arr;
            }, []);
            if(this.employeeInfo.firstName && this.employeeInfo.lastName){
                this.employeeInfo.fullName = this.employeeInfo.firstName.trim() + " " + this.employeeInfo.lastName.trim();
            }
            this.employeeInfo.privileges = this.privileges;
            if (this.employeeInfo.departmentType) {
                this.employeeInfo.departmentType = 1
            } else {
                this.employeeInfo.departmentType = 0;
            }

            if(this.isUpdate && this.images.length == 0 && this.employeeInfo.imageStore.length > 0) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredImage'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }

            if(!this.employeeInfo.employeeCode) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredEmployeeId'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            } else if(!this.employeeInfo.firstName) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredFirstName'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            } else if(!this.employeeInfo.firstName) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredLastName'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            } else if(!this.employeeInfo.username) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredUsername'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            } else if(!this.employeeInfo.birthday) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredBirth'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            } else if(!this.employeeInfo.email) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredEmail'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            } else if(!this.employeeInfo.houseNumber) {
                return this.$vs.notify({
                    text: this.$t('employeeScreen.requiredStreet'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }

            if(!this.isUpdate || (this.isUpdate && this.isUpdateImages)){
                if(this.images.length > 0){
                    await this.handleClickUploadFile();
                    this.employeeInfo.images = this.imagesId;
                } else {
                    this.employeeInfo.images = []
                }
            }

            if (!this.employeeInfo.cardId && this.images.length == 0) {
                return this.$vs.notify({
                    color: 'danger',
                    text: 'Hãy thêm dữ liệu khuôn mặt hoặc thiết bị ngoại vi!',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle'
                })
            }

            let url = this.isUpdate ? `/employee/update/${this.employeeInfo.employeeId}` : `/employee/create/${this.employeeInfo.organizationBranchId}`,
                data = this.isUpdate ? this.employeeInfo : [this.employeeInfo];
            this.$vs.loading();
            this.$crm.post(url, data).then((res) => {
                this.$vs.notify({
                    color: 'success',
                    text: `${this.isUpdate ? this.$t('button.update') : this.$t('button.create')} ${this.$t('menuOrganization.succPersonnel')}`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$router.push('/user/manage-employee')
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        confirmHandleClickUploadFile() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t('button.titleDelete'),
                text: this.$t('menuOrganization.oldData'),
                accept: this.handleClickUploadFile,
                acceptText: this.$t('statusActive.agree'),
                cancelText: this.$t('statusActive.cancel')
            })
        },
        handleClickUploadFile() {
            return new Promise((resolve) => {
                let formData = new FormData(), config = {};
                this.images.forEach((f, i) => {
                    formData.append('files', f.file);
                });
                config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                };

                this.$vs.loading();
                this.$crm.post('/employee/image/upload', formData, config).then((res) => {
                    this.imagesId = [];
                    this.imagesId = res.data;
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            });
        },
        employeeList() {
            this.$router.push(`/user/manage-employee${this.employeeInfo.organizationBranchId ? `?branch_id=${this.employeeInfo.organizationBranchId}` : ''}`).catch(() => {
            })
        }
    },
}
</script>

<style scoped>

</style>
